<template>
  <div headless :style="{ visibility: !collapsed ? 'visible' : 'hidden' }">
    <template v-if="!collapsed">
      <a-row>
        <a-col
          :lg="8"
          :md="12"
          :xs="24"
          class="my-2"
          v-if="
            authStore.config?.stocks_filtrable_columns?.general?.region_country
          "
        >
          <label>{{ $t("generic.titles.countries") }}</label>
          <a-select
            show-search
            allowClear
            :filter-option="filterOption"
            v-model:value="stocksStore.data.selected_country[0]"
            style="width: 95%; height: 45px"
            :placeholder="$t('generic.titles.countries')"
            class="native-select"
            :options="countriesList"
            @change="stocksStore.addSelectedCountry(country)"
          >
          </a-select>
        </a-col>
        <a-col
          :lg="8"
          :md="12"
          :xs="24"
          class="my-2"
          v-if="authStore.config?.stocks_filtrable_columns?.general?.sector"
        >
          <label>{{ $t("generic.titles.sectors") }}</label>
          <a-select
            show-search
            allowClear
            :filter-option="filterOption"
            v-model:value="stocksStore.data.selected_sector[0]"
            style="width: 95%; height: 45px"
            :placeholder="$t('generic.titles.sectors')"
            class="native-select"
            :options="sectorsList"
            @change="stocksStore.addSelectedSector(sector)"
          >
          </a-select>
        </a-col>
      </a-row>
      <a-row
        class="mt-4"
        v-if="authStore.config?.stocks_filtrable_columns?.general?.industry"
      >
        <a-col :md="8" :xs="24" class="my-2">
          <label>{{ $t("generic.titles.industries") }}</label>
          <a-select
            show-search
            allowClear
            :filter-option="filterOption"
            v-model:value="stocksStore.data.selected_industry[0]"
            style="width: 95%; height: 45px"
            :placeholder="$t('generic.titles.industries')"
            class="native-select"
            :options="industriesList"
            @change="stocksStore.addSelectedIndustry(industry)"
          >
          </a-select>
        </a-col>
      </a-row>
      <template v-for="(g, gi) in genericFilters" v-bind:key="gi">
        <a-row
          class="mt-5"
          gutter={16}
          v-if="authStore.config?.stocks_filtrable_columns?.general[g.field]"
        >
          <a-col :md="2" :xs="24" style="margin: 2px;">
            <span v-tooltip="true">{{ g.label }}</span>
          </a-col>
          <a-col :lg="3" :md="6" :xs="24" style="margin: 2px;">
            <a-select
              mode="select"
              allowClear
              style="width: 95%; height: 45px; margin: 10px 0"
              v-model:value="
                stocksStore.data.extraFiltersValues[g.field].operand
              "
              :token-separators="[',']"
              :placeholder="$t('generic.titles.operator')"
              class="native-select"
            >
              <a-select-option
                v-for="(f, i) in operands"
                :key="i + 'd'"
                :value="i"
                :title="$t(`operands.${f.name}`, f.name)"
              >
                {{ $t(`operands.${f.name}`, f.name) }}
              </a-select-option>
            </a-select>
          </a-col>
          <a-col :lg="3" :md="6" :xs="24" style="margin: 2px;">
            <template
              v-if="stocksStore.data.extraFiltersValues[g.field].operand !== 0"
            >
              <percent-input
                v-if="isPercent(g.field)"
                v-model="stocksStore.data.extraFiltersValues[g.field].value"
                style="width: 95%; height: 45px"
              ></percent-input>
              <a-input
                type="number"
                v-else
                :min="0"
                style="width: 95%; height: 45px"
                v-model:value="
                  stocksStore.data.extraFiltersValues[g.field].value
                "
              />
            </template>
          </a-col>
          <a-col
            :md="3"
            :xs="24"
            v-if="
              gi === 0 &&
              stocksStore.data.extraFiltersValues[g.field].operand !== 0
            "
            style="margin: 2px;"
          >
            <a-select
              mode="select"
              allowClear
              style="width: 95%; height: 45px; margin: 10px 0"
              v-model:value="stocksStore.data.extraFiltersValues[g.field].units"
              :token-separators="[',']"
              :placeholder="$t('generic.titles.units')"
              class="native-select"
            >
              <a-select-option
                v-for="(f, i) in multiplos"
                :key="i + 'd'"
                :value="i"
                :title="$t(`amount_units.${f.name}`, f.name)"
              >
                {{ $t(`amount_units.${f.name}`, f.name) }}
              </a-select-option>
            </a-select>
          </a-col>
        </a-row>
      </template>
      <a-row>
        <a-col :md="3" :xs="22" class="m-auto">
          <sdButton
            size="small"
            class="element-center mt-5"
            :loading="loading"
            type="primary"
            @click="searchStocks"
          >
            {{ $t("generic.buttons.search") }}
          </sdButton>
        </a-col>
      </a-row>
    </template>
  </div>
</template>

<script setup>
import { defineEmits, onMounted, computed, defineProps } from "vue";
import { useOperandStore } from "@/stores/OperandStore";
import { useStocksStore } from "@/stores/StocksStore";
import { useAuthStore } from "@/stores/AuthStore";
import { useI18n } from "vue-i18n";
import PercentInput from "@/components/wiseral/PercentInput";

defineProps({
  loading: { type: Boolean, required: false },
  collapsed: {
    type: Boolean,
    required: true,
    default: function () {
      return true;
    },
  }
});

const stocksStore = useStocksStore();
const operandStore = useOperandStore();
const authStore = useAuthStore();
const { t } = useI18n();

const genericFilters = operandStore.genericFilters.map((item) => ({
  ...item,
  label: t(`search_stocks.filters.${item.label}`, item.label),
}));

const emit = defineEmits(["searchStocks"]);
const searchStocks = () => {
  emit("searchStocks");
};

const operands = operandStore.operands;
const multiplos = operandStore.multiplos;

const countriesList = computed(() =>
  operandStore.countries.map((country) => ({
    value: country.id,
    label: t(`countries.${country.cat_name}`, country.cat_name),
  }))
);

const sectorsList = computed(() =>
  operandStore.sectors.map((sector) => ({
    value: sector.id,
    label: t(`sectors.${sector.name}`, sector.name),
  }))
);

const industriesList = computed(() =>
  operandStore.industries.map((industry) => ({
    value: industry.id,
    label: t(`industries.${industry.name}`, industry.name),
  }))
);

const isPercent = (field) =>
  authStore.config.master_stocks_columns[field]?.format?.format === "percent";

onMounted(() => {
  operandStore.getCountries("stocks");
  operandStore.getIndustries("stocks");
  operandStore.getSectors("stocks");

  genericFilters.map((gf) => {
    stocksStore.addFilter(gf.field);
  });
});

const filterOption = (input, option) => {
  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};
</script>

<style scoped lang="scss">
label {
  margin: 10px 0;
}

:deep(.native-select) {
  border: 1px #000 solid;
  padding: 0 !important;
  margin: 0 !important;
  .ant-select-selector {
    border: none !important;
    height: 100% !important;
  }

  &.ant-select-open {
    .ant-select-selector {
      border: none !important;
      height: 100% !important;
    }
  }
}

:deep(.ant-select-selection-item) {
  & > * {
    height: 100%;
    display: flex;
    align-items: center;
  }
}
:deep(.ant-input-number) {
  background-color: transparent !important;
}
</style>
