<template>
  <div>
    <Main class="general-background">
      <a-card class="search-card" style="margin-bottom: 5rem;">
        <a-col :xs="24" :md="16" class="search-tabs">
          <p
            class="search-tab-item"
            v-for="item in searchTabs"
            :key="item.id"
            @click="changeSearchTab(item.id)"
            :class="{ 'active': activeSearch === item.id, 'borde-r': item.id !== SearchCardItems.SEARCH_STOCK_CUSTOM }"
          >{{ $t(item.label) }}</p>
        </a-col>
        <a-row style="padding: 0 16px 8px 16px;">
          <a-col :xs="24" :md="16" v-if="activeSearch === SearchCardItems.SEARCH_STOCK_ASSETS">
            <asset-by-ticker
              v-model="isinTkr"
              @blur="resetTicker"
              :tickerType="'stocks'"
              :title="$t('search_stocks.search_asset')"
              @selectedSearch="groupChange"
              :loading="isLoadingFirst"
              :auto-filter="false"
            ></asset-by-ticker>
          </a-col>
          <a-col :xs="24">
            <filter-criteria-stocks
              @searchStocks="groupChangeFiltering"
              :loading="isLoadingSecond"
              :collapsed="activeSearch !== SearchCardItems.SEARCH_STOCK_CUSTOM"
            />
          </a-col>
        </a-row>
      </a-card>
      <a-row>
        <a-col :sm="24" :xs="24">
          <basket-card ref="fundsTable" assetType="master_stocks_columns">
          </basket-card>
        </a-col>
      </a-row>
      <founds-modal
        v-model="visible"
        :funds="stocksStore.stockList"
        :searcher="stocksStore.searchStocksByIsinTkr"
      ></founds-modal>
    </Main>
  </div>
</template>

<script setup>
import { Main } from "../styled";
import { ref, watch, onMounted } from "vue";

import AssetByTicker from "../../components/wiseral/searchAnalysis/AssetByTicker.vue";
import { useStocksStore } from "@/stores/StocksStore";
import FilterCriteriaStocks from "@/components/wiseral/filters/filter-criteria-stocks";
import { useBasketStore } from "@/stores/BasketStore";
import BasketCard from "@/components/wiseral/basket/BasketCard.vue";
import { message } from "ant-design-vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import FoundsModal from "@/components/wiseral/FoundsModal";
import SearchCardItems from "@/config/search/cardIDs";

const { t } = useI18n();

const searchTabs = [
  {
    id: SearchCardItems.SEARCH_STOCK_ASSETS,
    label: 'search_funds.search_asset'
  },
  {
    id: SearchCardItems.SEARCH_STOCK_CUSTOM,
    label: 'search_funds.select_filter_criteria'
  }
];

const router = useRouter();
const basketStore = useBasketStore();
const stocksStore = useStocksStore();

const visible = ref(false);
const isinTkr = ref([]);
const isLoadingFirst = ref(false);
const isLoadingSecond = ref(false);
const activeSearch = ref(SearchCardItems.SEARCH_STOCK_ASSETS);

const changeSearchTab = (tabKey) => {
  activeSearch.value = tabKey;
}

// eslint-disable-next-line no-unused-vars
const groupChange = async () => {
  isLoadingFirst.value = true;
  try {
    const result = await stocksStore.getStocks();
    console.log(result);
    if (result && Array.isArray(result)) {
      await basketStore.addAsset(result);
      message.success(t("funds_printer.added_funds"));
    }
  } catch (err) {
    console.log(err);
  } finally {
    isLoadingFirst.value = false;
  }
};

const groupChangeFiltering = async () => {
  isLoadingSecond.value = true;
  try {
    const result = await stocksStore.getStocksFiltering();
    if (result && Array.isArray(result)) {
      visible.value = true;
    }
  } catch (err) {
    console.log(err);
  } finally {
    isLoadingSecond.value = false;
  }
};

const resetTicker = () => {
  isinTkr.value = stocksStore.data.isin;
};

watch(
  () => isinTkr.value,
  () => {
    stocksStore.data.isin = isinTkr?.value.filter((e) => typeof e === "number");
  }
);

onMounted(async () => {
  const path = router.currentRoute.value.path;
  await basketStore.changeBasketUrl(path);
});
</script>
<style scoped lang="scss">
label {
  margin: 10px 0;
}

.native-select {
  &.select {
    border: 1px #000 solid;
    padding: 0 !important;
    margin: 0 !important;
    :deep(.ant-select-selector) {
      border: none !important;
      height: 100% !important;
    }
  }
}
.search-card {
  border: 1px solid #02B388;
  background-color: transparent;
  .ant-card-body {
    padding: 2rem !important;
  }
}
.search-tabs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 16px;
  padding: 8px 16px 0px 16px;
  & .search-tab-item {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 12px;
    padding-bottom: 12px;
    margin-bottom: 0 !important;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    font-size: 14px;
    &.active {
      background-color: #004851;
      color: white;
    }
    &:hover {
      background-color: #004851;
      color: white;
    }
    &.borde-r {
      border-right: 1px solid black;
    }
  }
}
</style>
